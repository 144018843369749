<template>
  <v-col cols="12" :md="cols">
    <v-file-input
      prepend-icon=""
      show-size
      outlined
      hide-details="auto"
      :key="keyProp"
      :accept="accept"
      :rules="rules"
      :placeholder="placeholder"
      :append-icon="icon"
      :label="label"
      @change="changeFile($event)"
    ></v-file-input>
  </v-col>
</template>

<script>
export default {
  props: [
    'keyProp',
    'value',
    'cols',
    'icon',
    'label',
    'placeholder',
    'accept',
    'rules',
    'value'
  ],
  data() {
    return {
      file: null
    }
  },
  methods: {
    changeFile(file) {
      if (file) this.createBase64FromFile(file)
    },
    createBase64FromFile(file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.$emit('changeFile', e.target.result)
      }
      reader.readAsDataURL(file)
    }
  }
}
</script>

<style></style>
