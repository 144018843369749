<template>
  <v-col cols="12" :md="cols">
    <v-checkbox
      v-model="check"
      outlined
      hide-details="auto"
      :key="keyProp"
      :label="label"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
    ></v-checkbox>
  </v-col>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'CheckBoxField',
  props: [
    'keyProp',
    'label',
    'cols',
    'row',
    'name',
    'rules',
    'valueField',
    'disabled',
    'readonly',
  ],

  data() {
    return {
      check: this.valueField || null,
    }
  },

  watch: {
    valueField: {
      immediate: true,
      handler(newValue, oldValue) {
        if ((newValue === null || newValue) && newValue !== oldValue) {
          this.check = newValue;
        }
      }
    },
    check: {
      immediate: true,
      handler() {
        this.$emit('update:value', this.check)
      }
    },
  },
}
</script>

<style scoped>
.label {
  font-weight: 600;
  color: rgb(98, 98, 98);
  margin-bottom: 5px;
}
</style>
