<template>
  <v-col cols="12" :md="cols">
    <v-textarea
      outlined
      v-model="text"
      hide-details="auto"
      :key="keyProp"
      :label="label"
      :placeholder="placeholder"
      :rules="rules"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxLength"
      :rows="rows"
      counter
    >
    </v-textarea>
  </v-col>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'TextAreaField',
  props: [
    'keyProp',
    'label',
    'placeholder',
    'cols',
    'row',
    'name',
    'rules',
    'valueField',
    'disabled',
    'readonly',
    'maxLength',
    'rows'
  ],

  data() {
    return {
      text: this.valueField || '',
    }
  },

  watch: {
    valueField: {
      immediate: true,
      handler(newText, oldText) {
        if ((newText === '' || newText === 0 || newText) && newText !== oldText) {
          this.text = newText
        }
      }
    },
    text: {
      immediate: true,
      handler() {
        this.$emit('update:value', this.text)
      }
    },
    clearInput() {
      this.text = ''
    }
  },
}
</script>

<style scoped>
.label {
  font-weight: 600;
  color: rgb(98, 98, 98);
  margin-bottom: 5px;
}
</style>
