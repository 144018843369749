<template>
  <v-col cols="12" :md="cols">
    <v-divider v-if="divider" class="mt-6"></v-divider>
    <TitleH2 class="mt-6" :title="label" />
    <p>{{ descricaoDivisor }}</p>
  </v-col>
</template>

<script>
import TitleH2 from '@/components/atoms/title/TitleH2.vue'
export default {
  props: {
    cols: Number,
    label: String,
    descricaoDivisor: String,
    divider: {
      type: Boolean,
      default: true
    }
  },
  components: {
    TitleH2
  }
}
</script>

<style></style>
