<template>
  <v-form class="form-fields fields" :ref="reference">
    <component
      v-for="field in fields"
      v-show="!field.hideInput"
      :text="field.text"
      :valueField="field.valueField"
      :value="field.value"
      :maskFormat="field.maskFormat"
      :disabled="field.disabled"
      :comTemplate="field.comTemplate"
      :keyValue="field.keyValue"
      :keyName="field.keyName"
      :accept="field.accept"
      :tituloItem="field.tituloItem"
      :textoNadaEncontrado="field.textoNadaEncontrado"
      :exibirAvatar="field.exibirAvatar"
      :items="field.items"
      :rules="field.rules"
      :label="field.required ? (field.label + ' *') : field.label"
      :maxLength="field.maxLength"
      :placeholder="field.placeholder"
      :cols="field.cols"
      :prependInnerIcon="field.prependInnerIcon"
      :is="field.field"
      :key="field.key || field.variableName"
      :keyProp="field.key"
      :type="field.type"
      :appendIcon="field.appendIcon"
      :hint="field.hint"
      :errorText="field.errorText"
      :helpText="field.helpText"
      :dateType="field.dateType"
      :readonly="field.readonly"
      :divider="field.divider"
      :clearable="field.clearable"
      :outlined="field.outlined"
      :exibirValidacao="field.exibirValidacao"
      :validadores="field.validadores"
      :iconeSenha="field.iconeSenha"
      :icon="field.icon"
      :icone="field.icone"
      :corIcone="field.corIcone"
      :erroValidacao="field.erroValidacao"
      :manterMascara="field.manterMascara"
      :validacaoPopup="field.validacaoPopup"
      :estilo="field.estilo"
      :evento="field.evento"
      :tipoBotao="field.tipoBotao"
      :descricaoDivisor="field.descricaoDivisor"
      :multiple="field.multiple"
      :rows="field.rows"
      :apenasNumero="field.apenasNumero"
      :itensSelecionados="field.itensSelecionados"
      :descricaoItem="field.descricaoItem"
      :retornarObjeto="field.retornarObjeto"
      @update:value="updateValueField($event, field)"
      @clickBotao="$emit('clickBotao', { field: field, evento: $event })"
      @change="$emit('change', field)"
      @changeFile="$emit('changeFile', { field: field, arquivo: $event })"
      @pesquisa="$emit('pesquisaSelect', { field: field, termo: $event })"
      @scrollDown="$emit('fimListaSelect', field)"
      @unmask="unmasked = $event"
      @click="$emit('clickSelect', $event)"
    />
    <slot name="body"></slot>
  </v-form>
</template>

<script>
import ButtonMain from '../buttons/ButtonMain.vue'
import InputField from '@/components/atoms/fields/InputField.vue'
import TextAreaField from '@/components/atoms/fields/TextAreaField.vue'
import FileInput from '@/components/atoms/fields/FileInput.vue'
import CheckBoxField from '@/components/atoms/fields/CheckBoxField.vue'
import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'
import InputValidacaoSenha from '@/components/atoms/fields/InputValidacaoSenha.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'
import DateField from '@/components/atoms/fields/DateField.vue'
import DividerMain from '@/components/atoms/fields/DividerMain.vue'
import AutocompletePaginadoMain from '@/components/atoms/fields/AutocompletePaginadoMain.vue'
import Vue from 'vue'

export default {
  components: {
    InputField,
    FileInput,
    TextAreaField,
    SelectField,
    InputMaskField,
    CheckBoxField,
    DividerMain,
    InputValidacaoSenha,
    AutocompletePaginadoMain,
    ButtonMain,
    DateField,
  },
  data() {
    return {
      inputsValues: {},
      unmasked: {}
    }
  },
  props: {
    fields: {
      required: true,
      default: {}
    },
    disabled: {
      type: Boolean
    },
    validate: {
      type: Number,
      default: 0,
    },
    reset: {
      type: Number,
      default: 0,
    },
    reference: {
      type: String,
      default: 'form',
    },
  },

  methods: {
    updateValueField(event, field) {
      if (field && field.variableName) {
        this.inputsValues[field.variableName] = this.unmasked ? this.unmasked : event
        this.unmasked = null
        this.fields[field.variableName].value = event
        this.$emit('values', { ...this.inputsValues, ...field })
      }
    },
  },
  watch: {
    validate() {
      this.$refs[this.reference].validate();
    },

    reset() {
      this.$refs[this.reference].reset();
    },

    fields: {
      immediate: true,
      handler() {
        Object.entries(this.fields).forEach((field) => {
          Vue.set(this.fields[field[0]], 'variableName', field[0])
          Vue.set(this.inputsValues, field[1].variableName, '')
        })
      }
    }
  },
}
</script>

<style scoped>
  .fields {
    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    align-items: flex-start;
    flex: 1;
  }

  .form-fields {
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
</style>
